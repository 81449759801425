<template>
  <div class="hovering__pad p-4">
    <div class="d-flex justify-content-between flex-wrap">
      <div class="w-lg-50 w-100">
        <h1>Рекламное агентство Easyprbot</h1>
        <div class="step-bullet mb-5 mt-4" :bullet-value="1">
          <span class="fw-500">Поток заявок на рекламу.</span>Мы размещаем вашу анкету в рекламной
          сети easyprbot и усиленно ее продвигаем по сравнению даже с платными тарифами на сайте.
        </div>
        <div class="step-bullet my-5" :bullet-value="2">
          <span class="fw-500"
            >Предоставим нашего менеджера и возьмем большую часть общения с заказчиками рекламы на
            себя.</span
          >
          Сюда входит и фильтрация входящих заявок, и договоренности о рекламе, и даже поиск
          рекламодателей.
        </div>
        <div class="step-bullet my-5" :bullet-value="2">
          <span class="fw-500">Поможем официально и безопасно получать оплату за рекламу</span>
        </div>
        <div class="step-bullet my-5" :bullet-value="4">
          <span class="fw-500">Обмен заказчиками рекламы внутри агентства</span>
        </div>
        <div class="step-bullet my-5" :bullet-value="5">
          <span class="fw-500">Сотрудничество с брендами</span>
        </div>
        <b-button variant="yellow" :to="{ name: 'pr-agency-apply' }" class="ph-42"
          >Хочу вступить в рекламное агентство</b-button
        >
      </div>

      <div class="w-lg-50 w-100" style="line-height: 13px !important"></div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped></style>
